import './App.css';
import useLocalStorage from "use-local-storage"
import {Toggle} from "./components/Toggle";

function App() {
  let prefers_darkness: boolean = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if(typeof prefers_darkness === "undefined"){
    prefers_darkness = true;
  }
  const [isDark, setIsDark] = useLocalStorage("isDark", prefers_darkness);


  return (
    <div className="App" data-theme={isDark ? "dark" : "light"}>
      <div style={{backgroundColor:'var(--primary-background-color)'}}>
          <Toggle handleChange={() => setIsDark(!isDark)} />
      </div>
      <header className="App-header">
        <p>
          This website will be a W.I.P. shortly
        </p>
        <p>
          This is going to be a repository of sorts for my own applications
        </p>
        <p>
          I purchased this url to motivate myself and force myself to fix this
        </p>
        <p>- Jace</p>
      </header>
    </div>
  );
}

export default App;
