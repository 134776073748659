import '../styles/Toggle.css'

import IconButton from "@mui/material/IconButton"
import DarkModeIcon from "@mui/icons-material/DarkMode"

export const Toggle = ({handleChange}) => {
    return (<>
        <IconButton
        id="dark-mode-button"
        onClick={handleChange}
        aria-label="Dark Mode"
        size="medium"
        className='toggle-dark'>
            <DarkModeIcon id="dark-mode-icon"/>
        </IconButton>
    </>)
}